<template>
    <div
        class="categories-container-select"
        v-loading="componentLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
    >
        <el-row :gutter="2">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-autocomplete
                    class="inline-input"
                    v-model="inputSelected"
                    :fetch-suggestions="querySearch"
                    placeholder="Cauta categoria"
                    @select="handleSelect"
                    :clearable="true"
                    @input="clearSelect"
                    :trigger-on-focus="false"
                >
                    <el-button class="show-cat-list" slot="append" @click="showCatList = !showCatList">
                        <svg-icon icon-class="sitemap" />
                    </el-button>
                </el-autocomplete>
            </el-col>
        </el-row>
        <el-row :gutter="2" v-if="showCatList">
            <div class="legend">
                <div class="legend-items">
                    <span>
                        <svg-icon class="red" icon-class="cancel" />
                        {{ $t('account.categoryaccess.notpermitted') }}</span
                    >
                    <span>
                        <svg-icon class="warning" icon-class="hourglass" />
                        {{ $t('account.categoryaccess.pendingrequest') }}</span
                    >
                    <span>
                        <svg-icon class="warning" icon-class="file" />
                        {{ $t('account.categoryaccess.waitingdoc') }}
                    </span>
                    <span>
                        <svg-icon class="green" icon-class="checkmark" />
                        {{ $t('account.categoryaccess.hasaccess') }}</span
                    >
                    <span>
                        <svg-icon class="danger" icon-class="minus" />
                        {{ $t('account.categoryaccess.accessrequestrejected') }}</span
                    >
                    <span>
                        <svg-icon class="blue" icon-class="unlock" />
                        {{ $t('account.categoryaccess.canrequestaccess') }} ({{ $t('account.categoryaccess.clickon') }}
                        <svg-icon class="blue" icon-class="unlock" />)</span
                    >
                </div>
            </div>
            <el-col v-for="(categ, index) in reactiveCategList" :key="index" :xs="20" :sm="16" :md="12" :lg="8" :xl="6">
                <c-list
                    @request-category-access="requestCategoryAccess"
                    @valid-selection="validSelection"
                    @category-selected="categorySelected"
                    :catLevel.sync="index"
                    :catList.sync="categ"
                    :selected.sync="selectedIds"
                ></c-list>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { getCategoriesForAccess, requestCategoryAccess, getAllCategories } from '@/api/cont';
import { EventBus } from '@/utils/Bus.js';

import { mapGetters } from 'vuex';

import forEach from 'lodash.foreach';
import union from 'lodash.union';

const list = () => import('./list.vue');
export default {
    components: {
        'c-list': list
    },
    props: ['categ'],
    data() {
        return {
            cCategory: 0,
            categList: [],
            selected: [],
            inputSelected: '',
            allCategs: [],
            showCatList: false,
            selectedCategs: [],
            componentLoading: true
        };
    },
    methods: {
        setLoading(val) {
            this.componentLoading = val;
        },
        setSelectInput() {
            this.inputSelected = '';
            for (const i in this.selectedCategs) {
                this.inputSelected += `${this.selectedCategs[i].categories_name} > `;
            }
            this.inputSelected = this.inputSelected.replace(/\>\s$/, '');
        },
        setCategList() {
            this.categList = [];

            const recursiveFindValue = (tree, parents, node) => {
                if (this.selectedIds.includes(tree.categories_id)) {
                    this.categList.push(node);
                }
                if (typeof tree.children !== 'undefined') {
                    forEach(tree.children, (subtree, index) => {
                        const maybeParents = union(parents, []);
                        if (typeof tree.categories_name !== 'undefined') {
                            maybeParents.push(tree);
                        }
                        recursiveFindValue(subtree, maybeParents, tree.children);
                    });
                }
                return true;
            };
            for (const i in this.allCategs) {
                recursiveFindValue(this.allCategs[i], [], this.allCategs);
            }
        },
        handleSelect(val) {
            if (typeof val.current !== 'undefined') {
                EventBus.$emit('cselected', val.current);
                this.$emit('selected', val.current);
            }
            this.selectedCategs = union(val.parents, [val.current]);
            this.setCategList();

            if (typeof this.inputSelected === 'undefined' || this.inputSelected === '') this.inputSelected = val.value;
        },
        querySearch(queryString, cb) {
            if (!queryString) {
                return cb([]);
            }

            const result = this.filterCategoriesByName(
                queryString
                    .split('>')
                    .pop()
                    .trim()
                    .toLowerCase()
            );

            cb(result || []);
        },
        filterCategoriesByName(search) {
            const results = [];

            function recursiveFindValue(tree, parents) {
                if (tree.categories_name.toLowerCase().includes(search) && parseInt(tree.hasAccess) === 2) {
                    const p = union(parents, [tree]);
                    results.push({
                        value:
                            p.length == 1
                                ? p[0].categories_name
                                : p.reduce((acc, tr) => {
                                      return (acc =
                                          (typeof acc === 'object' ? acc.categories_name : acc) +
                                          ` > ${tr.categories_name}`);
                                  }),
                        current: tree,
                        parents
                    });
                }
                if (typeof tree.children !== 'undefined') {
                    forEach(tree.children, (subtree, index) => {
                        const maybeParents = union(parents, []);
                        if (typeof tree.categories_name !== 'undefined') {
                            maybeParents.push(tree);
                        }
                        recursiveFindValue(subtree, maybeParents);
                    });
                }
                return true;
            }

            for (const i in this.allCategs) {
                recursiveFindValue(this.allCategs[i], []);
            }
            return results;
        },
        filterCategoriesById(categId) {
            const results = [];
            categId = parseInt(categId);

            function recursiveFindValue(tree, parents) {
                if (parseInt(tree.categories_id) === categId) {
                    const p = union(parents, [tree]);
                    results.push({
                        value:
                            p.length == 1
                                ? p[0].categories_name
                                : p.reduce((acc, tr) => {
                                      return (acc =
                                          (typeof acc === 'object' ? acc.categories_name : acc) +
                                          ` > ${tr.categories_name}`);
                                  }),
                        current: tree,
                        parents
                    });
                }
                if (typeof tree.children !== 'undefined') {
                    forEach(tree.children, (subtree, index) => {
                        const maybeParents = union(parents, []);
                        if (typeof tree.categories_name !== 'undefined') {
                            maybeParents.push(tree);
                        }
                        recursiveFindValue(subtree, maybeParents);
                    });
                }
                return true;
            }
            for (const i in this.allCategs) {
                recursiveFindValue(this.allCategs[i], []);
            }
            return results;
        },
        getAllCategories(cb, forceFetch = false) {
            this.setLoading(true);
            if (this.supplierCategories.length > 0 && !forceFetch) {
                this.setLoading(false);
                this.allCategs = this.supplierCategories;
                this.$store.commit('app/SET_ALREADY_CALL', false);
                this.$set(this.categList, 0, this.allCategs);
                if (typeof cb !== 'undefined') cb();
            } else {
                getAllCategories()
                    .then((res) => {
                        this.setLoading(false);
                        if (!res || !res.message) return false;
                        this.allCategs = res.message;
                        this.$store.commit('app/SET_SUPPLIER_CATEGS', res.message);
                        this.$store.commit('app/SET_ALREADY_CALL', false);
                        this.$set(this.categList, 0, this.allCategs);
                        if (typeof cb !== 'undefined') cb();
                    })
                    .catch((err) => {
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'getAllCategories'
                            }
                        });
                        this.setLoading(false);
                    });
            }
        },
        getCategories(level) {
            this.setLoading(true);
            getCategoriesForAccess({
                currentCategory: this.cCategory
            }).then((res) => {
                this.$set(this.categList, level, res.message);
            });
        },
        requestCategoryAccess(categ) {
            this.$confirm(`Sigur doriti acces la categoria ${categ.categories_name}?`, 'Atentie', {
                confirmButtonText: 'Da',
                cancelButtonText: 'Nu',
                type: 'warning'
            })
                .then(() => {
                    this.setLoading(true);
                    requestCategoryAccess({
                        categ_id: categ.categories_id
                    })
                        .then((res) => {
                            this.setLoading(false);
                            if (res.message && typeof res.message.success) {
                                this.sbMsg({
                                    type: 'success',
                                    message: `Cererea de acces a fost inregistrata. Imediat ce va fi aprobata veti primi notificare!`
                                });
                                this.resetFields();
                            }
                        })
                        .catch((err) => {
                            this.$reportToSentry(err, {
                                extra: {
                                    fn: 'requestCategoryAccess',
                                    params: {
                                        categ_id: categ.categories_id
                                    }
                                }
                            });
                            this.setLoading(false);
                        });
                })
                .catch((err) => {});
        },
        selectCategory(data) {
            this.$set(this.selectedCategs, data.nextLevel - 1, data.categObj);
            this.selectedCategs.length = data.nextLevel;
            this.setSelectInput();
            this.categList.length = data.nextLevel; // Removing any rendered categories from level 3 or more
            if (typeof data.categObj.children !== 'undefined') {
                this.$set(this.categList, data.nextLevel, data.categObj.children);
            }
        },
        resetFields() {
            this.getAllCategories(this.setCategList, true);
        },
        setDefaultCategory() {
            if (typeof this.categ === 'undefined' || this.categ === '' || this.categ == 0) return false;
            const result = this.filterCategoriesById(this.categ);
            if (result.length < 1) return false;
            this.handleSelect(result[0]);
        },
        categorySelected(data) {
            this.selectCategory(data);
        },
        validSelection(categ) {
            this.$emit('selected', categ);
        },
        clearSelect(val) {
            if (val === '') {
                this.cCategory = 0;
                this.selected = [];
                this.inputSelected = '';
                this.selectedCategs = [];
                this.$forceUpdate();
                this.$emit('clear');
                this.$emit('selected', {
                    categories_id: 0
                });
            }
        }
    },
    computed: {
        ...mapGetters({
            supplierCategories: 'app/supplierCategories',
            alreadyCalling: 'app/alreadyCalling'
        }),
        currentCategory() {
            return this.categId;
        },
        reactiveCategList() {
            return this.categList;
        },
        selectedIds() {
            return this.selectedCategs.map((item) => {
                return item.categories_id;
            });
        }
    },
    watch: {
        alreadyCalling(status) {
            if (!status && this.allCategs.length < 1) this.getAllCategories(this.setDefaultCategory);
        }
    },
    created() {
        if (!this.alreadyCalling) {
            this.$store.commit('app/SET_ALREADY_CALL', true);
            this.getAllCategories(this.setDefaultCategory);
        }
    },
    mounted() {},
    beforeDestroy() {
        EventBus.$off();
    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.categories-container-select .el-autocomplete {
    display: inline !important;
}

.categories-container-select {
    .legend-items {
        font-size: 12px;
        font-weight: bold;
        color: #303133;
        margin: 10px 0px;

        .svg-icon {
            font-size: 16px;
        }

        span {
            margin-right: 10px;
        }

        .svg-icon.red {
            color: #909399;
        }

        .svg-icon.green {
            color: #67c23a;
        }

        .svg-icon.blue {
            color: #409eff;
        }

        .svg-icon.warning {
            color: #e6a23c;
        }

        .svg-icon.danger {
            color: #f56c6c;
        }
    }
}
</style>
